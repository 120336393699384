<template>
  <div>
    <h2 class="pageTitle pt-5 mb-8">新規健診作成</h2>
    <div class="mx-12">
      <v-form ref="exam_form">
        <v-row>
          <v-text-field
            v-model="examName"
            :counter="20"
            :rules="nameRules"
            label="健診名"
            required
          ></v-text-field
        ></v-row>
        <v-row>
          <v-switch label="予診票" v-model="makePrediction"></v-switch>
        </v-row>
        <v-row class="ml-6 title" v-if="makePrediction">
          <v-col cols="2">予診票：</v-col>
          <v-col cols="2" v-if="!selectedPrediction[0]">
            <v-btn color="blue lighten-3" @click="dialog = true"
              >予診票を選択</v-btn
            >
          </v-col>
          <v-col cols="10" v-else class="font-weight-bold">
            <!-- <v-card class="py-2 text-center green lighten-4"> -->
            {{ selectedPrediction[0].title }}
            <v-icon @click="dialog = true" color="primary">mdi-pencil</v-icon>
            <!-- </v-card> -->
          </v-col>
        </v-row>
        <v-row>
          <v-switch label="領収書印刷" v-model="printReceipt"></v-switch
        ></v-row>
        <v-row class="ml-10 title" v-if="printReceipt">
          <v-col>
            <v-row> 領収書記載内容 </v-row>
            <v-row class="ml-2">
              <p class="body-2 blue--text text--darken-4 mt-4">
                領収書に記載する内容を入れてください。
              </p></v-row
            >
            <v-row class="ml-2">
              <v-text-field
                label="但し書き"
                suffix="として"
                v-model="printConfig.description"
                :rules="[(v) => !!v || '費用名を入力してください']"
              ></v-text-field
            ></v-row>
            <v-row class="ml-2">
              <v-text-field
                label="金額(消費税込)"
                suffix="円"
                v-model="printConfig.amount"
                :rules="[
                  (v) => !!v || '金額を入力してください',
                  (v) => /\d/.test(v) || '金額は半角数字で入力してください。',
                ]"
              ></v-text-field></v-row
          ></v-col>
        </v-row>
      </v-form>
      <v-row justify="end" v-if="checks.selectPrediction">
        <p class="body-2 red--text text-darken-3">予診票を選択してください。</p>
      </v-row>
      <v-row justify="end" class="">
        <v-btn large color="primary" @click="makePredictionFunc"
          >新規作成</v-btn
        >
      </v-row>
    </div>
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-text class="pt-6">
          <h2>予診票を選択</h2>
          <prediction-list @selected-data="dataSelected"></prediction-list>
          <!-- <v-row justify="end" v-if="checks.dialog_select_prediction">
            <p class="body-2 red--text text-darken-3">
              予診票を選択してください。
            </p>
          </v-row> -->
          <v-row justify="end" class="mx-2">
            <!-- <v-btn
              text
              class="mr-2"
              @click="
                dialog = false;
                selectedPrediction = [];
              "
              >閉じる</v-btn
            > -->
            <v-btn color="primary" @click="decidePrediction">決定</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from "firebase";
import predictionList from "../components/predictionList.vue";

export default {
  components: {
    predictionList,
  },
  data: () => ({
    step: 1,
    dialog: false,
    examName: "",
    makePrediction: false,
    selectedPrediction: [],
    printReceipt: false,
    printConfig: {
      description: "",
      amount: "",
    },
    nameRules: [
      (v) => !!v || "健診名を入力してください",
      (v) => (v && v.length <= 20) || "健診名は20文字以内で入力してください。",
    ],
    checks: {
      selectPrediction: false, // 新規作成が押された時に警告する用
    },
  }),
  methods: {
    dataSelected(data) {
      console.log(data[0]);
      [this.selectedPrediction[0]] = data;
      console.log(this.selectedPrediction);
      if (data[0]) {
        this.checks.selectPrediction = false;
      }
    },
    decidePrediction() {
      this.dialog = false;
    },
    makePredictionFunc() {
      if (!this.$refs.exam_form.validate()) {
        return;
      }
      if (this.makePrediction && !this.selectedPrediction[0]) {
        this.checks.selectPrediction = true;
        return;
      }
      const addData = {
        examName: this.examName,
        makePrediction: this.makePrediction,
        predictionData: this.selectedPrediction,
        printReceipt: this.printReceipt,
        printConfig: this.printConfig,
        created: new Date(),
        updated: new Date(),
      };
      firebase
        .firestore()
        .collection("exam_data")
        .add(addData)
        .then(() => {
          window.alert("作成が完了しました。");
          this.$router.push({ name: "allExam" });
        });
    },
  },
  watch: {},
  filters: {},
};
</script>
<style>
.pageTitle {
  border-bottom: solid 3px rgba(0, 0, 0, 0.87);
}
.pageBody {
  margin-top: 60px;
  margin-bottom: 40px;
}
</style>
